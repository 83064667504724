var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"userEdit",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Informações profissionais ")])],1),_c('br'),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateUser()}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"profession","vid":"profession","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Profissão","label-for":"profession"}},[_c('b-form-select',{attrs:{"id":"profession","options":_vm.professions,"state":errors.length > 0 ? false:null,"name":"profession","label":"text"},model:{value:(_vm.user.profession),callback:function ($$v) {_vm.$set(_vm.user, "profession", $$v)},expression:"user.profession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"crm","vid":"crm","rules":"required_if:profession,medico_oftalmologista|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"CRM","label-for":"crm"}},[_c('b-form-input',{attrs:{"id":"crm","vid":"crm","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.user.crm),callback:function ($$v) {_vm.$set(_vm.user, "crm", $$v)},expression:"user.crm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"crmUf","vid":"crmUf","rules":"required_if:profession,medico_oftalmologista"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"CRM UF","label-for":"crmUf"}},[_c('b-form-select',{attrs:{"id":"crmUf","label":"text","options":_vm.states,"state":errors.length > 0 ? false:null},model:{value:(_vm.user.crm_uf),callback:function ($$v) {_vm.$set(_vm.user, "crm_uf", $$v)},expression:"user.crm_uf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Salvar mudanças ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }