<template>
  <b-card>
    <!-- form -->
    <!-- form -->
    <validation-observer
      ref="newPassword"
      v-slot="{invalid}"
    >
      <b-form
        @submit.prevent="changePassword()"
      >
        <b-row>
          <!-- new password -->
          <!-- password -->
          <b-col cols="6">
            <b-form-group
              label-for="register-password"
              label="Senha"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                vid="password"
                rules="required|min:6|verify_password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <!-- repeat password -->
            <b-form-group
              label-for="register-repeat_password"
              label="Repetir senha"
            >
              <validation-provider
                v-slot="{ errors }"
                name="repeat_password"
                vid="repeat_password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-repeat_password"
                    v-model="repeatPassword"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-repeat_password"
                    placeholder="············"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              :disabled="invalid"
            >
              Salvar nova senha
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      repeatPassword: '',
      // validation
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userState() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    ...mapActions('profile', ['ActionChangePassword']),
    async changePassword() {
      await this.$refs.newPassword.validate().then(success => {
        if (success) {
          this.ActionChangePassword({
            password: this.password,
            id: this.userState.us
          }).then(async (api) => {
            switch (api.data.status) {
              case 200:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                break
              case 400:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
                break
              default:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: api.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
            }
          })
        }
      })
    }
  },
}
</script>
