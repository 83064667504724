<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informações pessoais</span>
      </template>

      <account-setting-general
        v-if="user"
        :general-data="user"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informações Profissionais</span>
      </template>

      <account-setting-professional
        v-if="user"
        :information-data="user"
      />
    </b-tab>

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="MapPinIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Endereço</span>
      </template>

      <account-setting-information
        v-if="user"
        :information-data="user"
      />
    </b-tab>

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mudança de senha</span>
      </template>

      <account-setting-password
        v-if="user"
        :information-data="user"
      />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <!-- <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notificação</span>
      </template>

      <account-setting-notification
        v-if="user"
        :notification-data="user"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingProfessional from './AccountSettingProfessional.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingProfessional,
  },
  data() {
    return {
      options: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
  },
}
</script>
